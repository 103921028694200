import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

 // Function to download the PDF
 export const handelPdfDownload = async (setLoading = '', quizId, setSelectedQuiz='', setModelQuizId = '', quiz='') => {
  try {
    setLoading(true); 
    if (quiz !== '')
    {
      quizId = quiz.id;
      setSelectedQuiz(quiz);
      setModelQuizId(quizId);
    }
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const apiUrl = `api/quiz/quizform/downloadpdf/quiz_id=${quizId}`;
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Page-Name': currentPage,
      },
      responseType: 'blob',  // Important for file download
    });

    // Create a blob from the response data
  const blob = new Blob([response.data], { type: 'application/pdf' });
  // Create a link element to trigger the download
  const downloadlink = document.createElement('a');
  downloadlink.href = window.URL.createObjectURL(blob);
  // Set the filename - check for 'content-disposition' header for dynamic filename
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'agkiyaQuiz.pdf';  // Default filename

  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/);
    if (match.length > 1) {
      filename = match[1];
    }
  }

  downloadlink.download = filename;
  document.body.appendChild(downloadlink);
  // Trigger the download
  downloadlink.click();

  // Clean up
  document.body.removeChild(downloadlink);
} catch (error) {
  console.error("Error downloading PDF", error);
}
finally {
  setLoading(false);  // Set loading to false when the process is complete
}
};

// Function to download the word file
export const handelWordDownload = async (setLoading = '', quizId, setSelectedQuiz='', setModelQuizId = '', quiz='') => {
  try {
    setLoading(true); 
    if (quiz !== '')
    {
      quizId = quiz.id;
      setSelectedQuiz(quiz);
      setModelQuizId(quizId);
    }
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const apiUrl = `api/quiz/quizform/downloadword/quiz_id=${quizId}`;
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Page-Name': currentPage,
      },
      responseType: 'blob',  // Important for file download
    });

  // Create a blob from the response data
  const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  // Create a link element to trigger the download
  const downloadlink = document.createElement('a');
  downloadlink.href = window.URL.createObjectURL(blob);
  // Set the filename - check for 'content-disposition' header for dynamic filename
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'agkiyaQuiz.docx';  // Default filename

  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/);
    if (match.length > 1) {
      filename = match[1];
    }
  }

  downloadlink.download = filename;
  document.body.appendChild(downloadlink);
  // Trigger the download
  downloadlink.click();

  // Clean up
  document.body.removeChild(downloadlink);
} catch (error) {
  console.error("Error downloading WORD file", error);
}
finally {
  setLoading(false);  // Set loading to false when the process is complete
}
};

// Function to download the Excell
export const handelExcelDownload = async (setLoading = '', quizId, setSelectedQuiz='', setModelQuizId = '', quiz='') => {
  try {
    setLoading(true); 
    if (quiz !== '')
    {
      quizId = quiz.id;
      setSelectedQuiz(quiz);
      setModelQuizId(quizId);
    }
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const apiUrl = `api/quiz/quizform/downloadexcel/quiz_id=${quizId}`;
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Page-Name': currentPage,
      },
      responseType: 'blob',  // Important for file download
    });
    // Create a blob from the response data
  const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  // Extract the filename from 'Content-Disposition' header
  const contentDisposition = response.headers['content-disposition'];
  let filename = 'agkiyaQuiz.xlsx';  // Default filename

  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/);
    if (match && match.length > 1) {
      filename = match[1];
    }
  }

  link.download = filename;  // Set the filename
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Clean up
  document.body.removeChild(link);

    
} catch (error) {
  console.error("Error downloading PDF", error);
}
finally {
  setLoading(false);  // Set loading to false when the process is complete
}
};


export const downloadChart = async (chartRef) => {
  if (chartRef.current) {
    const canvas = await html2canvas(chartRef.current);
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'chart.png';
    link.click();
  }
};

// Print chart
export const printChart = async (chartRef) => {
  if (chartRef.current) {
    const canvas = await html2canvas(chartRef.current);
    const image = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(image, 'PNG', 10, 10, 180, 160);
    pdf.autoPrint();
    window.open(pdf.output('bloburl'), '_blank');
  }
};

