
export const isValidEmail = (email) => {
  if (!email.value || !/\S+@\S+\.\S+/.test(email.value))
    return false;
  else
    return true;
}

export const isValidPassword = (password) => {
  if (!password.value || password.value.length < 6)
    return false;
  else
    return true;
}

export const isValidPhone = (phone) => {
  if (phone.value && (phone.value.length <= 9 || phone.value.length > 10))
    return false;
  else
    return true;
}

export const isRequiredFiledIsEmpty = (field) => {
  if (!field.value || field.value.length < 1)
    return false;
  else
    return true;
}