// Import icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizResultIcon from '@mui/icons-material/RuleFolder';
import QuizListIcon from '@mui/icons-material/SnippetFolder';
import QuestionListIcon from '@mui/icons-material/QuestionAnswer';
import UserListIcon from '@mui/icons-material/Contacts';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CostReportIcon from '@mui/icons-material/Money';
import BillingReportIcon from '@mui/icons-material/Payments';
import LayersIcon from '@mui/icons-material/Layers';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaymentsIcon from '@mui/icons-material/Payments';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


  // Map icons from string to actual icon components
const iconMap = {
    'DashboardIcon': <DashboardIcon  style={{ color: '#2e97ff' }}/>,
    'QuizResultIcon': <QuizResultIcon style={{ color: '#2e97ff' }}/>,
    'QuizListIcon': <QuizListIcon style={{ color: '#2e97ff' }}/>,
    'QuestionListIcon': <QuestionListIcon style={{ color: '#2e97ff' }}/>,
    'UserListIcon': <UserListIcon style={{ color: '#2e97ff' }}/>,
    'LogoutIcon': <LogoutIcon style={{ color: '#2e97ff' }}/>,
    'ShareIcon': <ShareIcon style={{ color: '#2e97ff' }}/>,
    'SummarizeIcon': <SummarizeIcon style={{ color: '#2e97ff' }}/>,
    'CostReportIcon': <CostReportIcon style={{ color: '#2e97ff' }}/>,
    'BillingReportIcon': <BillingReportIcon style={{ color: '#2e97ff' }}/>,
    'LayersIcon': <LayersIcon style={{ color: '#2e97ff' }}/>,
    'AdsClickIcon': <AdsClickIcon style={{ color: '#2e97ff' }}/>,
    'PeopleIcon': <PeopleIcon style={{ color: '#2e97ff' }}/>,
    'GroupsIcon': <GroupsIcon style={{ color: '#2e97ff' }}/>,
    'GroupIcon': <GroupIcon style={{ color: '#2e97ff' }}/>,
    'EngineeringIcon': <EngineeringIcon style={{ color: '#2e97ff' }}/>,
    'PaymentsIcon': <PaymentsIcon style={{ color: '#2e97ff' }}/>,
    'ManageAccountsIcon': <ManageAccountsIcon style={{ color: '#2e97ff' }}/>
  };

  export default iconMap;
