import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import {fetchCodeDescriptionMap} from '../Utility/getCodeDescription';
import { isValidEmail, isValidPassword, isValidPhone, isRequiredFiledIsEmpty } from "../Utility/validations";

const SignUp = () => {
  
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');
  const [languageError, setLanguageError] = React.useState(false);
  const [languageErrorMessage, setLanguageErrorMessage] = React.useState('');
  const [countryCodeError, setCountryCodeError] = React.useState(false);
  const [countryCodeErrorMessage, setCountryCodeErrorMessage] = React.useState('');

  const [login, setLogin] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [user_type, user_type] = useState("");
  const [userTypes, setUserTypes] = useState([]); 
  const [languages, setUserLanguages] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);  
  const [selectedUserType, setselectedUserType] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [country_code, setSelectedCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);


  // Fetch userTypes from the backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        

        const typesData = await fetchCodeDescriptionMap('user_types');
        setUserTypes(typesData);

        const languageData = await fetchCodeDescriptionMap('user_languages');
        setUserLanguages(languageData);

        const countryCodeData = await fetchCodeDescriptionMap('country_codes');
        setCountryCodes(countryCodeData);
      } catch (error) {
        console.error('Error fetching UserTypes:', error);
      }
    };

    fetchData();
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      if(validateInputs())
      {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("login", login);
        formData.append("password", password);
        formData.append("username", username);
        formData.append("user_type", selectedUserType);
        formData.append("user_language", selectedLanguage);
        formData.append("country_code", country_code);
        formData.append("phone", phone);

        await axios.post('api/user/create', formData)
            .then((response) => {
              navigate("/Login");
            })
            .catch((error) => {
              console.error('Error in creating new user:', error);
            });
      }
      
    } catch (error) {
      console.error("Faile to create the User");
    }
  };

  const validateInputs = () => {
    const login = document.getElementById('login');
    const username = document.getElementById('username');
    const password = document.getElementById('password');
    const phone = document.getElementById('phone');
    let isValid = true;

    if (!isValidEmail(login)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!isValidPassword(password)) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(username)) {
      setNameError(true);
      setNameErrorMessage('Name is required.');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage('Phone is required.');
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!country_code) {
      setCountryCodeError(true);
      setCountryCodeErrorMessage('Country Code is required.');
      isValid = false;
    } else {
      setCountryCodeError(false);
      setCountryCodeErrorMessage('');
    }

    if (!selectedLanguage) {
      setLanguageError(true);
      setLanguageErrorMessage('Application Language is required.');
      isValid = false;
    } else {
      setLanguageError(false);
      setLanguageErrorMessage('');
    }

    if (!isValidPhone(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage('Phone number must have 10 digits.');
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    return isValid;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check if the file is an image
      const fileType = selectedFile.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        setSelectedFile(selectedFile);
      } else {
        alert("Please upload a valid image file (JPEG, JPG, or PNG).");
      }
    }
  };

  return (
    <Container direction="column" justify="space-between">
      <Stack sx={{
              justifyContent: 'top',
              height: '100dvh',
              p: 2,
            }}>             
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                  >
                    Sign up
                  </Typography>

                  <Box component="form" onSubmit={handleSignUp} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl>
                      <FormLabel htmlFor="username">Full name</FormLabel>
                      <TextField
                        autoComplete="username"
                        name="username"
                        required
                        fullWidth
                        id="username"
                        placeholder="Jon Snow"
                        onChange={(e) => setUsername(e.target.value)}
                        error={nameError}
                        helperText={nameErrorMessage}
                        color={nameError ? 'error' : 'primary'}
                      />
                    </FormControl>
                    <FormControl>
                    <FormLabel htmlFor="login">Login</FormLabel>
                      <TextField
                        required
                        fullWidth
                        id="login"
                        placeholder="your@email.com"
                        name="login"
                        autoComplete="login"
                        variant="outlined"
                        onChange={(e) => setLogin(e.target.value)}
                        error={emailError}
                        helperText={emailErrorMessage}
                        color={emailError ? 'error' : 'primary'}
                      />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      placeholder="••••••"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                      error={passwordError}
                      helperText={passwordErrorMessage}
                      color={passwordError ? 'error' : 'primary'}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="password">User Photo</FormLabel>
                    <input type="file" onChange={handleFileChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="user_language" required>Application Language</FormLabel>
                    <Select
                      required
                      labelId="user_language"
                      id="user_language"
                      value={selectedLanguage}
                      label="Language"
                      onChange={(e) => setSelectedLanguage(e.target.value)}
                      error={languageError}
                      helperText={languageErrorMessage}
                      color={languageError ? 'error' : 'primary'}
                      
                    >
                      <MenuItem value=""><em>Select Language</em></MenuItem>
                      {languages.map((language) => (
                        <MenuItem key={language.code} value={language.code}>
                          {language.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="user_type" required>
                          User Type
                      </FormLabel>

                      <Select
                              required
                              labelId="user_type"
                              id="user_type"
                              value="ROV"
                              label="user_type"
                              onChange={(e) => setselectedUserType(e.target.value)}
                              disabled='true'
                            >
                              <MenuItem value="">
                                    <em>Select User Type</em>
                                  </MenuItem>
                                  {userTypes.map((user_type) => (
                                    <MenuItem key={user_type.code} value={user_type.code}>
                                      {user_type.description}
                                    </MenuItem>
                                  ))}
                            </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="country_code" required>
                          Country Code
                      </FormLabel>

                      <Select
                        required
                        labelId="country_code"
                        id="country_code"
                        value={country_code}
                        label="Country Code"
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        error={countryCodeError}
                        helperText={countryCodeErrorMessage}
                        color={countryCodeError ? 'error' : 'primary'}
                        
                      >
                        <MenuItem value="">
                              <em>Select Country Code</em>
                            </MenuItem>
                            {countryCodes.map((dd) => (
                              <MenuItem key={dd.code} value={dd.code}>
                                {dd.description}
                              </MenuItem>
                            ))}
                      </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="phone" required>
                          Phone
                      </FormLabel>
                      <TextField
                        autoComplete="phone"
                        name="phone"
                        fullWidth
                        id="phone"
                        placeholder="0123456789"
                        onChange={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        helperText={phoneErrorMessage}
                        color={phoneError ? 'error' : 'primary'}
                      />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={validateInputs}
                  >
                    Sign up
                  </Button>
                  <Typography sx={{ textAlign: 'center' }}>
                    Already have an account?{' '}
                    <span>
                      <Link
                        href="/Login"
                        variant="body2"
                        sx={{ alignSelf: 'center' }}
                      >
                        Sign in
                      </Link>
                    </span>
                  </Typography>

                </Box>

                <Divider>
                  <Typography sx={{ color: 'text.secondary' }}></Typography>
                </Divider>      

      </Stack>
        
    </Container>  
    
  );
};

export default SignUp;