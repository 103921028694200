import axios from "axios";

export const fetchUserNames = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage, 
      },
    };
    const apiUrl = 'api/user/get_user_names';
    const response = await axios.get(apiUrl, config);
    return response.data.codevalues;
  } catch (error) {
    console.error('Error fetching User Names:', error);
  }
};

export const fetchCodeDescriptionMap = async (codeDescriptionOf) => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage,  
      },
    };
    const apiUrl = 'api/codevalue/'+codeDescriptionOf;
    const response = await axios.get(apiUrl, config);
    
    return response.data.codevalues; // Return the complexities data
  } catch (error) {
    console.error('Error fetching {codeDescriptionOf:}' , error);
    throw error; // Throw the error so it can be handled where the function is called
  }
};

export const getCodeDescription = (code, codeDescriptionMap) => {
  const description = codeDescriptionMap.find((description) => description.code === code);
  return description ? description.description : code; 
};

export const fetchQuizNames = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage,  // Pass the current page name in a custom header
      },
    };
    const apiUrl = 'api/quiz/get_quiz_names';
    const response = await axios.get(apiUrl,config);
    return response.data.codevalues;
  } catch (error) {
    console.error('Error fetching quiz names:', error);
  }
};

export const fetchGroupNames = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage,  // Pass the current page name in a custom header
      },
    };
    const apiUrl = 'api/shared_group/get_group_names';
    const response = await axios.get(apiUrl,config);
    return response.data.codevalues;
  } catch (error) {
    console.error('Error fetching group names:', error);
  }
};

export const fetchParticipantNames = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage,  // Pass the current page name in a custom header
      },
    };
    const apiUrl = 'api/participants/get_participant_names';
    const response = await axios.get(apiUrl,config);
    return response.data.codevalues;
  } catch (error) {
    console.error('Error fetching Participant names:', error);
  }
};

export const fetchTenantNames = async () => {
  try {
    const token = localStorage.getItem("token");
    const currentPage = window.location.pathname; // Get the current page or route
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Page-Name': currentPage,  // Pass the current page name in a custom header
      },
    };
    const apiUrl = 'api/tenant/get_tenant_names';
    const response = await axios.get(apiUrl,config);
    return response.data.codevalues;
  } catch (error) {
    console.error('Error fetching Tenant names:', error);
  }
};

