import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, getCodeDescription} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
// Now call the function after it's defined
const { formattedToDate, formattedFromDate } = getDefaultDateRange();

const QuizResponseReportPage = () => {
  const navigate = useNavigate();
  const [users, setUserNames] = useState([]);
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [modelError, setModelError] = useState(null); 
  const [error, setError] = useState(null); 
  //model popup feilds
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modelSubject, setModelSubject] = useState('');
  const [modelColData, setModelColData] = useState('');
  const [modelRowData, setModelRowData] = useState('');
  const [modelTotalScore, setModelTotalScore] = useState('');
  const [modelCreatedDate, setModelCreatedDate] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 

  const [filters, setFilters] = useState({
    create_date_from: formattedFromDate,
    create_date_to: formattedToDate,
    quiz_id:''
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      setError('');
      setModelError('');
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route

        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiUrl = `api/quiz/response/responses?from_date=${filters.create_date_from}&to_date=${filters.create_date_to}`;
        const response = await axios.get(apiUrl,config);
        setData(response.data.quiz_form_response);  // Set the fetched data to state
        const userNameData = await fetchUserNames();
        setUserNames(userNameData);
      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
        } finally {
          setLoading(false);  // Stop the loading spinner
        }
    };

    fetchData();
  }, [filters, navigate]);

  const handleViewClick = async (report) => {
    try {

      setModelSubject(report.subject);
      setModelTotalScore(report.score);


      const token = localStorage.getItem("token");
      const currentPage = window.location.pathname;
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Page-Name': currentPage,
        },
      };

      const apiUrl = `api/quiz/response/quizforms?quiz_id=${report.quiz_id}&quiz_sharing_id=${report.quiz_sharing_id}`;
      const response = await axios.get(apiUrl, config);
      
      const quizForms = response.data.quiz_forms;

      // Ensure modelColData is initialized as an array
      const modelColumns = [
        { field: 'question_number', headerName: getString('QLQU'), width: 50, editable: false },
        { field: 'question', headerName: getString('QLQU'), width: 300, editable: false },
        { field: 'response', headerName: getString('QLRS'), width: 300, editable: false },
        { field: 'correct_answer', headerName: getString('QLCR'), width: 130, editable: false },
        { field: 'responder_response', headerName: getString('QRRR'), width: 170, editable: false },
        { field: 'score', headerName: getString('QRPS'), width: 170, editable: false },
      ];

      const modelRows = quizForms.map((form) => ({
        id: form.quiz_forms_id,
        question_number: form.question_number,
        question: form.question,
        response: form.response,
        correct_answer: form.correct_answer,
        responder_response: form.responder_response,
        score: form.score,
      }));

      setModelRowData(modelRows);
      setModelColData(modelColumns);  // Make sure to set columns properly

      setModelCreatedDate(report.create_date);
      setIsReadOnly(true);
      setIsViewEditModalOpen(true);  // Open the dialog
    } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setModelError(error.response.data.error );
        }
        if (error.response?.status === 401) {
          navigate('/logout');
        }
      } finally {
        setLoading(false);
      }
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 100,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    { field: 'subject', headerName: getString('GLSB'), width: 200, editable: false },
    { field: 'user_id', headerName: getString('GLCR'), width: 210, editable: false },
    { field: 'responder_name', headerName: getString('GLRB'), width: 200, editable: false },
    { field: 'score', headerName: getString('GLSC'), width: 150, editable: false },
    { field: 'create_date', headerName: getString('GLCD'), width: 200, editable: false },

  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((report, index) => ({
    id: index, 
    quiz_sharing_id: report.quiz_sharing_id,
    responder_name: report.responder_name,
    email_id:report.email_id ,
    create_date: getFormatedDate(report.create_date),
    user_id: getCodeDescription(report.user_id,users),
    subject:report.subject, 
    quiz_id:report.quiz_id,                    // A unique ID for each row                                              
    score: report.score
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
    {
      alert(getString('GLDC'));
      return;
    }
    if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
    {
      alert(getString('GLDC'));
      return;
    }
     
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('QSTR')}</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography>{getString('GLFL')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={3}>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <TextField
                    label={getString('GLFR')}
                    type="date"
                    name="create_date_from"
                    value={filters.create_date_from}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <TextField
                      label={getString('GLTO')}
                      type="date"
                      name="create_date_to"
                      value={filters.create_date_to}
                      onChange={handleFilterChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                </FormControl>
              </FormGrid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick

                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold', // Bold font for the header titles
                  }
                }}
              />
            </Box>      
          </Grid>
          {error && <MessageSection error={getString(error)} />}                
        </Grid>

        {/* View/ Edit Question Modal */}
        <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{isReadOnly ? getString('QQQD') : getString('QQEQ')}</DialogTitle>
            <DialogContent>
              <TextField label={getString('GLSB')} fullWidth margin="normal" value={modelSubject} disabled='true' />
              <TextField label={getString('QRPS')}    fullWidth margin="normal" value={modelTotalScore}  InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('QSSO')} fullWidth margin="normal" value={modelCreatedDate} disabled='true' />
              <Typography variant="h6" gutterBottom>{getString('QQLT')}</Typography>
              <DataGrid
                rows={modelRowData}
                columns={Array.isArray(modelColData) ? modelColData : []}  // Ensure it's an array
                pageSize={5}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
            </DialogActions>
            {modelError && <MessageSection error={getString(modelError)} />}
        </Dialog>

    </Container>
);
};

export default QuizResponseReportPage;
