export const getString = (code) => {
  try{
    const cachedDataString = localStorage.getItem('system_string');
    const cachedData = JSON.parse(cachedDataString);
    if(cachedData){
      const metadataItem = cachedData.data.metadata.find(item => item.code === code);
      return metadataItem ? metadataItem.description : code; 
    }
    else
      return code;
  } catch (error) {
    console.error('Error in getting system string from cache for code :', code);
  }
  
};

export const getUserData = () => {
  try{
    const cachedDataString = localStorage.getItem('login_user');
    const cachedData = JSON.parse(cachedDataString);
    if(cachedData){
      return cachedData.data.users[0];
    }
    else
      return '';
  } catch (error) {
    console.error('Error in getting system string from cache for code :');
  }
  
};

