import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import ModalForm from '../common/modalform';
import FormControl from '@mui/material/FormControl';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, fetchCodeDescriptionMap, getCodeDescription, fetchTenantNames} from '../Utility/getCodeDescription';
import {handelPdfDownload} from '../Utility/download';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import SendIcon from '@mui/icons-material/Send';
import {getString} from '../Utility/getSystemString';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

// Now call the function after it's defined
const { formattedToDate, formattedFromDate } = getDefaultDateRange();
const QuizListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [statuses, setStatus] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [users, setUserNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //model popup feilds
  const [selectedQuiz, setSelectedQuiz] = useState(null); 
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modelSubject, setModelSubject] = useState('');
  const [modelFile, setModelFile] = useState('');
  const [modelNumOfQuestion, setModelNumOfQuestion] = useState(''); 
  const [modelDuration, setModelDuration] = useState(''); 
  const [modelCreatedDate, setModelCreatedDate] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [modelCategory, setModelCategory] = useState('');
  const [modelComplexity, setModelComplexity] = useState('');
  const [modelLanguage, setModelLanguage] = useState('');
  const [modelStatus, setModelStatus] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false); 



  const [filters, setFilters] = useState({
    create_date_from: formattedFromDate,
    create_date_to: formattedToDate,
    user_id:''
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname;
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  
          },
        };
        const apiUrl = `api/quiz/quizzes?create_date_from=${filters.create_date_from}&create_date_to=${filters.create_date_to}`;
        const response = await axios.get(apiUrl, config);
        setData(response.data.quizzes);  

        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatus(statusesData);

        const complexitiesData = await fetchCodeDescriptionMap('complexities');
        setComplexities(complexitiesData);

        const CategoriesData = await fetchCodeDescriptionMap('quiz_categories');
        setCategories(CategoriesData);

        const languagesData = await fetchCodeDescriptionMap('quiz_languages');
        setLanguages(languagesData);

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string')
            setError(error.response.data.error );
          if(error.status === 401)
            navigate('/logout');
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (quiz) => {
    setSelectedQuiz(quiz);
    setModelSubject(quiz.subject); 
    setModelFile(quiz.uploaded_file); 
    setModelNumOfQuestion(quiz.num_questions); 
    setModelDuration(quiz.duration?parseInt(quiz.duration):'');
    setModelComplexity(quiz.complexity); 
    setModelCategory(quiz.hQuiz_category_code);
    setModelLanguage(quiz.hQuiz_language_code);
    setModelStatus(quiz.hStatus);
    setModelCreatedDate(quiz.create_date);
    setModelTenant(quiz.tenant_id);
    setModelLastUpdate(quiz.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (quiz) => {
    setSelectedQuiz(quiz);
    setModelSubject(quiz.subject); 
    setModelFile(quiz.uploaded_file); 
    setModelNumOfQuestion(quiz.num_questions); 
    setModelDuration(quiz.duration?parseInt(quiz.duration):'');
    setModelComplexity(quiz.complexity);
    setModelCategory(quiz.hQuiz_category_code);
    setModelLanguage(quiz.hQuiz_language_code);
    setModelStatus(quiz.hStatus);
    setModelCreatedDate(quiz.create_date);
    setModelTenant(quiz.tenant_id);
    setModelLastUpdate(quiz.last_update_date);
    setIsReadOnly(false);
    setIsViewEditModalOpen(true); // Open the modal
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle Edit button click
  const handleShareClick = (quiz) => {
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setSelectedQuiz(quiz);
    setIsModalOpen(true);
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canClose = true;
    // Perform API call or state update to save changes
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    const updatedQuiz = {
      ...selectedQuiz,
      subject: modelSubject,
      quiz_category_code: modelCategory,
      status: modelStatus,
      quiz_language_code: modelLanguage,
      duration: modelDuration
    };

    try {
      const token = localStorage.getItem("token");
      const currentPage = window.location.pathname;
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Page-Name': currentPage,
        },
      };
      const prevData = await axios.patch(`api/quiz/update/quizzes_id=${selectedQuiz.quizzes_id}`, updatedQuiz, config);
      // Update the user data in the state after successful save
      setData((prevData) =>
        prevData.map((quiz) =>
          quiz.quizzes_id === selectedQuiz.quizzes_id ? updatedQuiz : quiz
        )
      );
      if(prevData.status === 200)
        setSuccess(prevData.data.msg);
      
    } catch (error) {
      canClose=false;
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        setError(error.response.data.error );
        setModelError(error.response.data.error );
      }
      if(error.status === 401)
        navigate('/logout');
    }
    if(canClose)
    {
      setIsViewEditModalOpen(false); // Close the modal after saving
      setSelectedQuiz(null);
    }
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  

  const handleSave = () => {
    console.log("Data saved successfully!");
    // You can add additional logic here after the modal data is saved
    setIsModalOpen(false); // Close the modal after saving
  };

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 300,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Pdf Download Icon */}
          <IconButton
            onClick={() => handelPdfDownload(setLoading, params.row.id, setSelectedQuiz, params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <PictureAsPdfIcon />
          </IconButton>

          {/* Word Download Icon */}
          <IconButton
            onClick={() => handelWordDownload(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <DescriptionIcon />
          </IconButton>

          {/* Excel Download Icon */}
          <IconButton
            onClick={() => handelExcelDownload(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <TableChartIcon />
          </IconButton>

          {/* Share Icon */}
          <IconButton
            onClick={() => handleShareClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <SendIcon />
          </IconButton>
          {isModalOpen && (
            <ModalForm
            onSave={handleSave}
            token={localStorage.getItem("token")} // Pass the JWT token
            id ={selectedQuiz.id}
            apiUrl="api/sharing/create"  // Pass the API endpoint
          />
          )}
        </>
      ),
    },

    { field: 'subject', headerName: getString('GLSB'), width: 210, editable: false },
    { field: 'user_id', headerName: getString('GLCR'), width: 210, editable: false },
    { field: 'complexity', headerName: getString('GLCX'), width: 110, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 110, editable: false },
    { field: 'quiz_category_code', headerName: getString('GLCT'), width: 100, editable: false },
    { field: 'quiz_language_code', headerName: getString('GLLN'), width: 100, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((quizzes, index) => ({
    id: quizzes.quizzes_id,                      // A unique ID for each row                     
    subject: quizzes.subject, 
    user_id:getCodeDescription(quizzes.user_id,users),             
    num_questions: quizzes.num_questions,
    duration: quizzes.duration,            
    uploaded_file: quizzes.uploaded_file,
    complexity: getCodeDescription(quizzes.complexity,complexities),
    hComplexity: quizzes.complexity,
    status: getCodeDescription(quizzes.status,statuses),
    hStatus: quizzes.status,statuses,
    quiz_category_code: getCodeDescription(quizzes.quiz_category_code,categories), 
    hQuiz_category_code: quizzes.quiz_category_code,             
    quiz_language_code: getCodeDescription(quizzes.quiz_language_code,languages),   
    hQuiz_language_code: quizzes.quiz_language_code,         
    create_date: getFormatedDate(quizzes.create_date),
    last_update_date: getFormatedDate(quizzes.last_update_date), 
    tenant_id: quizzes.tenant_id,
    quizzes_id: getCodeDescription(quizzes.quizzes_id, tenants)
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
    {
      alert(getString('GLDC'));
      return;
    }
    if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
    {
      alert(getString('GLDC'));
      return;
    }
     
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  

  // Function to download the PDF
  const handelWordDownload = async (quiz) => {
    try {
      setSelectedQuiz(quiz);
      setLoading(true); 
      const token = localStorage.getItem("token");
      const currentPage = window.location.pathname; // Get the current page or route
      const apiUrl = 'api/quiz/quizform/downloadword/quiz_id='+quiz.id;
      const response = await axios.get(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Page-Name': currentPage,
        },
        responseType: 'blob',  // Important for file download
      });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    // Create a link element to trigger the download
    const downloadlink = document.createElement('a');
    downloadlink.href = window.URL.createObjectURL(blob);
    // Set the filename - check for 'content-disposition' header for dynamic filename
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'agkiyaQuiz.docx';  // Default filename

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match.length > 1) {
        filename = match[1];
      }
    }

    downloadlink.download = filename;
    document.body.appendChild(downloadlink);
    // Trigger the download
    downloadlink.click();

    // Clean up
    document.body.removeChild(downloadlink);
  } catch (error) {
    if (error.response && error.response.data && typeof error.response.data.error === 'string') 
      setError(error.response.data.error );
  }
  finally {
    setLoading(false);  // Set loading to false when the process is complete
  }
  };

  // Function to download the PDF
  const handelExcelDownload = async (quiz) => {
    try {
      setSelectedQuiz(quiz);
      setLoading(true); 
      const token = localStorage.getItem("token");
      const currentPage = window.location.pathname; // Get the current page or route
      const apiUrl = 'api/quiz/quizform/downloadexcel/quiz_id='+quiz.id;
      const response = await axios.get(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Page-Name': currentPage,
        },
        responseType: 'blob',  // Important for file download
      });
      // Create a blob from the response data
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Extract the filename from 'Content-Disposition' header
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'agkiyaQuiz.xlsx';  // Default filename

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match && match.length > 1) {
        filename = match[1];
      }
    }

    link.download = filename;  // Set the filename
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);

      
  } catch (error) {
    if (error.response && error.response.data && typeof error.response.data.error === 'string') 
      setError(error.response.data.error );
  }
  finally {
    setLoading(false);  // Set loading to false when the process is complete
  }
  };

  return (
    <Container>    
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('QLTL')}</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography>{getString('GLFL')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={3}>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <TextField
                    label={getString('GLFR')}
                    type="date"
                    name="create_date_from"
                    value={filters.create_date_from}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <TextField
                      label={getString('GLTO')}
                      type="date"
                      name="create_date_to"
                      value={filters.create_date_to}
                      onChange={handleFilterChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                </FormControl>
              </FormGrid>
            </Grid>
          </AccordionDetails>
        </Accordion>
          <Grid container spacing={3}>
            
            <Grid item xs={12}>
              <Box>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Bold font for the header titles
                    }
                  }}
                />
              </Box> 
            </Grid>                
          </Grid>
          {error && <MessageSection error={getString(error)} />}
          {info && <MessageSection info={getString(info)} />}
          {success && <MessageSection success={getString(success)} />}

          {/* View/ Edit Quiz Modal */}
          <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{isReadOnly ? getString('QLDL') : getString('QLEQ')}</DialogTitle>
            <DialogContent>
              <TextField label={getString('GLSB')} fullWidth margin="normal" value={modelSubject} onChange={(e) => setModelSubject(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
                        
              <TextField select label={getString('GLCT')} fullWidth  margin="normal" value={modelCategory} onChange={(e) => setModelCategory(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
                {categories.map((dd) => (
                  <MenuItem key={dd.code} value={dd.code}>
                    {dd.description}
                  </MenuItem>
                ))}
              </TextField>

              <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} onChange={(e) => setModelStatus(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
                {statuses.map((dd) => (
                  <MenuItem key={dd.code} value={dd.code}>
                    {dd.description}
                  </MenuItem>
                ))}
              </TextField>
              <TextField label={getString('QGDR')} fullWidth margin="normal" value={modelDuration} onChange={(e) => setModelDuration(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              

              <TextField label={getString('GLCX')} fullWidth margin="normal" value={modelComplexity}  InputProps={{ readOnly: isReadOnly,}} />
             

              

              <TextField disabled='true' select label={getString('GLLN')} fullWidth  margin="normal" value={modelLanguage} onChange={(e) => setModelLanguage(e.target.value)} InputProps={{ readOnly: isReadOnly,}} >
                {languages.map((dd) => (
                  <MenuItem key={dd.code} value={dd.code}>
                    {dd.description}
                  </MenuItem>
                ))}
              </TextField>

              <TextField label={getString('GLNQ')} fullWidth margin="normal" value={modelNumOfQuestion} disabled='true' />
              <TextField label={getString('QLUF')} fullWidth margin="normal" value={modelFile} disabled='true' />
              <TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreatedDate} disabled='true' />
              <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />
              <TextField label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} disabled='true' />
              
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
              {!isReadOnly && (
                <Button onClick={handleSaveChanges} variant="contained">
                  {getString('GLSV')}
                </Button>
              )}
            </DialogActions>
            {modelError && <MessageSection error={getString(modelError)} />}
          </Dialog>
    </Container>
);
};

export default QuizListPage;
