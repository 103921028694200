import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, getCodeDescription} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

// Now call the function after it's defined
const { formattedToDate, formattedFromDate } = getDefaultDateRange();

const QuizResultPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  // State to handle errors
  const [users, setUserNames] = useState([]);

  const [filters, setFilters] = useState({
    create_date_from: formattedFromDate,
    create_date_to: formattedToDate
  });


  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiUrl = `api/report/quizresponsereport?create_date_from=${filters.create_date_from}&create_date_to=${filters.create_date_to}`;
        
        const response = await axios.get(apiUrl, config);

        setData(response.data.report);  // Set the fetched data to state

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);
      } catch (error) {
        if(error.status === 401)
            navigate('/logout');
        setError('Error fetching data');
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };
    fetchData();
  }, [filters, navigate]);

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  const columns = [
    { field: 'user_id', headerName: getString('GLCR'), width: 190, editable: false },
    { field: 'subject', headerName: getString('GLSB'), width: 190, editable: true },
    { field: 'num_questions', headerName: getString('GLNQ'), width: 200, editable: false },
    { field: 'total_shared', headerName: getString('GLST'), width: 120, editable: false },
    { field: 'total_responded', headerName: getString('GLRB'), width: 120, editable: false },
    { field: 'response_percent', headerName: getString('GLCP'), width: 130, editable: false },
    { field: 'create_date', headerName: getString('GLCD'), width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((report, index) => ({
    id: report.quizzes_id,                      // A unique ID for each row                     
    user_id: getCodeDescription(report.user_id,users),                          
    subject: report.subject,
    num_questions: report.num_questions,
    total_shared: report.total_shared,
    total_responded: report.total_responded,              
    response_percent: report.response_percent.toString() + ' %',
    create_date: getFormatedDate(report.create_date)
  }));
 
    // Handle form submit to set filters
    const handleFilterChange = (e) => {
      if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
        {
          alert(getString('GLDC'));
          return;
        }
        if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
        {
          alert(getString('GLDC'));
          return;
        }
      const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: value,
      });
    };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('QRTL')}</Typography>
        <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                  label={getString('GLFR')}
                  type="date"
                  name="create_date_from"
                  value={filters.create_date_from}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </FormControl>
            </FormGrid>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                
              </FormControl>
            </FormGrid>
            
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                    label={getString('GLTO')}
                    type="date"
                    name="create_date_to"
                    value={filters.create_date_to}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
              </FormControl>
            </FormGrid>
          </Grid> 
        </AccordionDetails>
      </Accordion>

        <Grid container spacing={3}>
          

            <Grid item xs={12}>
              <Box>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick

                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Bold font for the header titles
                    }
                  }}
                />
              </Box>      
            </Grid>                
          </Grid>
    </Container>
);
};

export default QuizResultPage;
