import React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container} from '@mui/material';
import {getUserRoleFromToken} from '../Utility/tokenParsar';
import AdminDashboardPage from './adminDashboard';
import UserDashboardPage from './userDashboard';
import ManagerDashboardPage from './managerDashboard';
import RoverDashboardPage from './roverDashboard';


const DashboardPage = () => {
  
  const [error, setError] = useState(null); 
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false); 
  const [isRover, setIsRover] = useState(false); 
  const [isUser, setIsUser] = useState(false); 
  const [isManager, setIsManager] = useState(false); 
  
  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {        
        const userRole = getUserRoleFromToken()
        if(userRole === 'ADMN')
            setIsAdmin(true)
        if(userRole === 'USR')
          setIsUser(true)
        if(userRole === 'ROV')
          setIsRover(true)
        if(userRole === 'MGR')
          setIsManager(true)
      } catch (error) {
        setError('Error fetching data');
        if(error.status === 401)
          navigate('/logout');
        console.error('Error fetching data: ', error);
      } finally {
        
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <Container>
      {isAdmin && (<AdminDashboardPage/>)}
      {isManager && (<UserDashboardPage/>)}
      {isUser && (<ManagerDashboardPage/>)}
      {isRover && (<RoverDashboardPage/>)}
  </Container>

  );
}
export default DashboardPage;
