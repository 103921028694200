import * as React from 'react';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ModalForm from '../common/modalform';
import SendIcon from '@mui/icons-material/Send';
import {handelPdfDownload, handelWordDownload, handelExcelDownload} from '../Utility/download';
import {getString} from '../Utility/getSystemString';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const QuizForm = () => {
  const location = useLocation();
  //const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // Add loading state
  const data = location.state?.data || {};
  const quiz_id = location.state.quiz_id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSave = () => {
    console.log("Data saved successfully!");
    // You can add additional logic here after the modal data is saved
    setIsModalOpen(false); // Close the modal after saving
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  const columns = [
    { field: 'No', headerName: getString('QLQN'), width: 50, editable: false,},
    { field: 'Question', headerName: getString('QLQU'), width: 450, editable: false,},
    { field: 'Options', headerName: getString('QLRS'), width: 400, editable: false,},
    { field: 'Answer', headerName: getString('QLCR'), width: 100, editable: false,}
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = Object.keys(data).map((key) => ({
    id: key,                    
    No: data[key].No,             
    Question: data[key].Question,            
    Options: data[key].Options,
    Answer: data[key].Answer.toUpperCase() 
  }));
  
  //Todo handel grid data download
  const handlQuizFormSubmit = (event) => {
    event.preventDefault();  // Prevent the page from reloading
    
  };

  return (
      <Container>
        <form onSubmit={handlQuizFormSubmit}>
          <CssBaseline enableColorScheme />
          <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
            <Box sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Grid container spacing={3}>
                <Typography variant="h4" gutterBottom>{getString('QLT2')}</Typography>
              
                <FormGrid size={{ xs: 12 }}>
                  <Box>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: 'bold', // Bold font for the header titles
                        }
                      }}
                    />
                  </Box>    
              </FormGrid> 
              <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
                <FormGrid size={{ xs: 14, md: 3 }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 190 }}>
                  <Box sx={{ '& button': { m: 1 } }}>                            
                      {
                        loading ? (<CircularProgress size={24}/>) : 
                        (
                          <Button variant="contained" size="small" 
                          onClick={() => handelPdfDownload(setLoading, quiz_id)}>{getString('GLDP')}</Button>
                        )
                      }
                    </Box> 
                  </FormControl> 
                </FormGrid>
                <FormGrid size={{ xs: 14, md: 3 }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                    <Box sx={{ '& button': { m: 1 } }}>                            
                      {
                        loading ? (<CircularProgress size={24}/>) : 
                        (
                          <Button variant="contained" size="small" onClick={() => handelExcelDownload(setLoading, quiz_id)}>{getString('GLDE')}</Button>
                        )
                      }
                    </Box> 
                    </FormControl> 
                </FormGrid>
                <FormGrid size={{ xs: 14, md: 3 }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                    <Box sx={{ '& button': { m: 1 } }}>
                        {
                          loading ? (<CircularProgress size={24}/>) : 
                          (
                            <Button variant="contained" size="small" onClick={() => handelWordDownload(setLoading, quiz_id)}>{getString('GLDW')}</Button>
                          )
                        }
                      </Box> 
                    </FormControl> 
                </FormGrid> 
                <FormGrid size={{ xs: 14, md: 3 }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 190 }}>
                    <Box sx={{ '& button': { m: 1 } }}>
                          <Button variant="contained" size="small" onClick={openModal} endIcon={<SendIcon />}>{getString('GLSQ')}</Button>
                            {isModalOpen && (
                              <ModalForm
                              onSave={handleSave}
                              token={localStorage.getItem("token")} // Pass the JWT token
                              id ={quiz_id}
                              apiUrl="api/sharing/create"  // Pass the API endpoint
                            />
                            )}
                    </Box> 
                  </FormControl> 
                </FormGrid>
              </Grid> 
            </Grid>
          </Box>                  
        </Grid>
      </form>
    </Container>
  );
}
export default QuizForm;