import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchCodeDescriptionMap, getCodeDescription, fetchUserNames, fetchTenantNames} from '../Utility/getCodeDescription';
import { isRequiredFiledIsEmpty } from "../Utility/validations";
import MessageSection from '../common/message';
import {getTenantIdFromToken} from '../Utility/tokenParsar';



const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const { formattedToDate, formattedFromDate } = getDefaultDateRange();
const SharingGroupListPage = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [tenants, setTenants] = useState([]);

  const [statuses, setStatus] = useState([]);
  const [userNames, setUserNames] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null); 


  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modeTitle, setModelTitle] = useState('');
  const [modelname, setModelName] = useState('');
  const [modelStatus, setModelStatus] = useState('');
  const [modelComment, setModelComment] = useState('');
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');


  const [filters, setFilters] = useState({
    status: 'ACTV',
    from_date: formattedFromDate,
    to_date: formattedToDate
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; 
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        const apiUrl = `api/shared_group/read?from_date=${filters.from_date}&to_date=${filters.to_date}&status=${filters.status}`;
        const response = await axios.get(apiUrl, config);
        setData(response.data.shared_group);

        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatus(statusesData);

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string')
          setError(error.response.data.error );
        if(error.status === 401)
          navigate('/logout');
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (group) => {
    setSelectedGroup(group); 
    setModelName(group.name); 
    setModelStatus(group.hStatus);
    setModelComment(group.comment);
    setModelCreated(group.create_date);
    setModelTenant(group.htenant_id);
    setModelLastUpdate(group.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('VIEW'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (group) => {
    setSelectedGroup(group); 
    setModelName(group.name); 
    setModelStatus(group.hStatus);
    setModelComment(group.comment);
    setModelCreated(group.create_date);
    setModelTenant(group.htenant_id);
    setModelLastUpdate(group.last_update_date);
    setIsReadOnly(false);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('EDIT'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle add button click
  const handleAddClick = () => {
    setSelectedGroup(null);
    setModelName(''); 
    setModelStatus('ACTV');
    setModelTenant(getTenantIdFromToken);
    setModelComment('');
    setIsReadOnly(false);
    setIsViewEditModalOpen(true);
    setIsNewRecord(true); 
    setModelTitle(getString('CRET'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle delete button click
  const handleDeleteClick =  async (group) => {
    setSelectedGroup(group); // Set the selected user for editing
    try {
      const token = localStorage.getItem("token");
      const currentPage = window.location.pathname; 
      const config = {
        headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Page-Name': currentPage,
            },
          };
          const delRes = await axios.delete(`api/shared_group/delete/shared_group_id=${group.id}`, config);
          const apiUrl = `api/shared_group/read`;
          const response = await axios.get(apiUrl, config);
          setData(response.data.shared_group);
          if(delRes.status === 200)
            setSuccess(delRes.data.msg);

    } catch (error) {
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
    }  
  };

  const validateInputs = () => {
    let isValid = true;
    const name = document.getElementById('name');

    if (!isRequiredFiledIsEmpty(name)) {
      setNameError(true);
      setNameErrorMessage(getString('VMNR'));
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    return isValid;
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canClose= true
    if(validateInputs())
    {
      const newGroup = {
        name: modelname,
        status: modelStatus,
        comment: modelComment,
        tenant_id:modelTenant,
      };
  
      const updatedGroup = {
        ...selectedGroup,
        name: modelname,
        status: modelStatus,
        comment:modelComment,
        tenant_id:modelTenant,
      };
      try {

        
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname;
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        if (isNewRecord) {
          // Save new record
          const response = await axios.post(`api/shared_group/create`, newGroup, config);
          setData((prevData) => [...prevData, response.data.shared_group]);
          if(response.status === 201)
            setSuccess(response.data.msg);
        } else {
          const prevData = await axios.patch(`api/shared_group/update/shared_group_id=${selectedGroup.shared_group_id}`, updatedGroup, config);
          // Update the user data in the state after successful save
          setData((prevData) =>
            prevData.map((group) =>
              group.shared_group_id === selectedGroup.shared_group_id ? updatedGroup : group
            )
          );

          if(prevData.status === 200)
            setSuccess(prevData.data.msg);
        }
      } catch (error) {
        canClose=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
          setModelError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
  
      if (canClose)
      {
        setIsViewEditModalOpen(false); // Close the modal after saving
        setSelectedGroup(null);
        setIsNewRecord(false);
      }      
    }    
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }


  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 200,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Icon */}
          <IconButton
            onClick={() => handleDeleteClick(params.row)}
            aria-label="delete"
            sx={{ color: '#2e97ff'}}
          >
            <DeleteIcon />
          </IconButton>

        </>
      ),
    },

    { field: 'name', headerName: getString('GLNM'), width: 230, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 160, editable: true },
    { field: 'user_id', headerName: getString('GLCR'), width: 150, editable: false },
    { field: 'comment', headerName: getString('GLCM'), width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((group, index) => ({
    id: group.shared_group_id,                                          
    name: group.name,
    comment: group.comment,            
    status: getCodeDescription(group.status,statuses),
    hStatus: group.status, 
    user_id: getCodeDescription(group.user_id,userNames),
    hUser_id: group.user_id,
    create_date: group.create_date ? getFormatedDate(group.create_date) : '',
    last_update_date: group.last_update_date ? getFormatedDate(group.last_update_date) : '',
    tenant_id: getCodeDescription(group.tenant_id,tenants),
    htenant_id: group.tenant_id,
    shared_group_id: group.shared_group_id
  }));

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    if(e.target.name === 'to_date' && e.target.value < filters.from_date)
    {
      alert(getString('GLDC'));
      return;
    }
    if(e.target.name === 'from_date' && e.target.value > filters.to_date)
    {
      alert(getString('GLDC'));
      return;
    }
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Container>
      <CssBaseline enableColorScheme />
      <Typography variant="h4" gutterBottom>{getString('AQSG')}</Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                  label={getString('GLFR')}
                  type="date"
                  name="from_date"
                  value={filters.from_date}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </FormControl>
            </FormGrid>
            
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                    label={getString('GLTO')}
                    type="date"
                    name="to_date"
                    value={filters.to_date}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
              </FormControl>
            </FormGrid>

            <FormGrid size={{ xs: 12, md: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField select label={getString('GLS')} name="status" value={filters.status} onChange={handleFilterChange} fullWidth >
                    {statuses.map((dd) => (
                      <MenuItem key={dd.code} value={dd.code}>
                        {dd.description}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </FormGrid>
          </Grid>  
        </AccordionDetails>
      </Accordion>

      <IconButton color="primary" onClick={() => handleAddClick()}> <AddIcon fontSize="large"/> </IconButton>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid>       
      </Grid>
      {error && <MessageSection error={getString(error)} />}
      {info && <MessageSection info={getString(info)} />}
      {success && <MessageSection success={getString(success)} />}

      {/* View/ Edit User Modal */}
      <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
        <DialogTitle>{modeTitle}</DialogTitle>
        <DialogContent>
          <TextField
            label={getString('GLNM')}
            fullWidth
            margin="normal"
            value={modelname}
            name="name"
            id="name"
            onChange={(e) => setModelName(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? 'error' : 'primary'}
            
          />

          <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} name="status" id="status" onChange={(e) => setModelStatus(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
            {statuses.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required name='tenant' id='tenant' select label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} onChange={(e) => setModelTenant(e.target.value)}  InputProps={{ readOnly: isReadOnly,}}>
            {tenants.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>

        
          <TextField label={getString('GLCM')} fullWidth margin="normal" value={modelComment} onChange={(e) => setModelComment(e.target.value)} multiline rows={4} InputProps={{ readOnly: isReadOnly,}} />
          {isReadOnly && <TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} disabled='true' />}
          {isReadOnly && <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
          {!isReadOnly && ( <Button onClick={handleSaveChanges} variant="contained">  {getString('GLSV')} </Button> )}
        </DialogActions>
        {modelError && <MessageSection error={getString(modelError)} />}
      </Dialog>
        
    </Container>
  );
};
export default SharingGroupListPage;
