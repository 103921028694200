import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Box from '@mui/material/Box';
import { Container, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Free from '../assets/free.png'; 
import Personal from '../assets/personal.png'; 
import Enterprise from '../assets/enterprise.png'; 
import Pro from '../assets/pro.png'; 
import {getString} from '../Utility/getSystemString';
import { getUserLoginIdFromToken } from "../Utility/tokenParsar";
import MessageSection from '../common/message';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const userEmail = getUserLoginIdFromToken();
const userToken = localStorage.getItem("token");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


const SubscriptionPage = ({ amount, currency, email }) => {
    const [error, setError] = useState(null);  
    const [info, setInfo] = useState(null);  
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [priceData, setPriceData] = useState([]); 
    const [individualPriceLabel, setIndividualPriceLabel] = useState('');
    const [subscriptionPriceLabel, setSubscriptionPriceLabel] = useState('');

    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');

    useEffect(() => {

      const fetchData = async () => {
        try {
          setError(false);
          setInfo(false);
          setSuccess(false);
          
          const token = localStorage.getItem("token");
          const currentPage = window.location.pathname; // Get the current page or route
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Page-Name': currentPage,
            },
          };
          const apiUrlPlan = `api/metadata/getuserpaymentplans`;
          const planResponse = await axios.get(apiUrlPlan, config);
          setPriceData(planResponse.data.price_plan); 

        } catch (error) {
            if (error.response && error.response.data && typeof error.response.data.error === 'string') {
              setError(error.response.data.error );
            }
            if(error.status === 401)
              navigate('/logout');
          } finally {
            setLoading(false);  // Stop the loading spinner
          }
      };
  
      fetchData();

      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);

      if (query.get('success')) {
        setSuccess(true);
        setSessionId(query.get('session_id'));
      }

      if (query.get('canceled')) {
        setSuccess(false);
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
      }
    }, [sessionId, navigate]);

    // Handle form submit to set filters
    const goToPayment = async (value) => {
      const currentPage = window.location.pathname; // Get the current page or route
    
      const response = await fetch("/api/payment/create-checkout-session", {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json',
          'Page-Name': currentPage,
                },
              body: JSON.stringify({ "mode":value, "currency":"eur", "customer_email": userEmail }),
          });
        const { sessionId } = await response.json();

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error(error);
        }  
      }; 
    
      // Handle form submit to set filters
      const sendEmail = async (value) => {
        window.location.href = 'mailto:agkiya@agkiya.tech';
    };
    
    useEffect(() => {
      if (priceData) {
        priceData.forEach((dd) => {
          if (dd.code === 'LPIP') {
            setIndividualPriceLabel(dd.message);
          } else if (dd.code === 'LPPP') {
            setSubscriptionPriceLabel(dd.message);
          }
        });
      }
    }, [priceData]);

    // Render loading spinner or error message
    if (loading) {
      return <div>Loading...</div>;
    }
    
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
              m: 1,
              width: '100%',
              height: '90%',
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid size={3}>
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <img src={Free} alt="Free Subscription" style={{ width: '50%', height: '50%', cursor: 'pointer', objectFit: 'cover' }} />
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '138px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>{getString('LFGD')}</Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '50px', display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="h4" gutterBottom>{getString('LPFP')}</Typography>
                  </Box>
                </Item>
              </Grid>
              <Divider />              
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <Button variant="contained" size="small" color="primary">{getString('LBFR')}</Button>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '380px' }}>

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LFFL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LFQL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LFSL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LFL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LFV')}</React.Fragment>}/>
                      </ListItem>
                    </List>
                  </Box>
                </Item>
              </Grid>
              <Divider />
            </Grid>

            <Grid size={3}>
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <img src={Personal} alt="Individual Subscription" style={{ width: '50%', height: '50%', cursor: 'pointer', objectFit: 'cover' }} onClick={() => goToPayment("INDI")} />
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box sx={{ pl: 2, height: '165px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>{getString('LIGD')}</Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '50px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h4" gutterBottom>{individualPriceLabel}</Typography>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <Button variant="contained" size="small" color="primary"  onClick={() => goToPayment("INDI")}>{getString('LBIN')}</Button>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '380px' }}>

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LIFL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LIQL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LISL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LIL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LIV')}</React.Fragment>}/>
                      </ListItem>
                    </List>
                  </Box>
                </Item>
              </Grid>
              <Divider />  
            </Grid>


            <Grid size={3}>
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <img src={Pro} alt="Premium Subscription" style={{ width: '50%', height: '50%', cursor: 'pointer', objectFit: 'cover' }} onClick={() => goToPayment("SUBS")}/>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '138px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>{getString('LPGD')}</Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '50px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h4" gutterBottom>{subscriptionPriceLabel}</Typography>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <Button variant="contained" size="small" color="primary"  onClick={() => goToPayment("SUBS")}>{getString('LBPR')}</Button>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '380px' }}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LPFL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LPQL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LPSL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LPL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LPV')}</React.Fragment>}/>
                      </ListItem>
                    </List>
                  </Box>
                </Item>
              </Grid>
              <Divider />              
            </Grid>
            
            
            <Grid size={3}>
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <img src={Enterprise} alt="Enterprise Subscription" onClick={() => sendEmail()} style={{ width: '50%', height: '50%', cursor: 'pointer', objectFit: 'cover' }} />
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '138px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>{getString('LEGD')}</Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '50px', display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h6. Heading" gutterBottom>{getString('LPEP')}</Typography>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <Button variant="contained" size="small" color="primary" onClick={() => sendEmail()}> {getString('LBET')}</Button>
                  </Box>
                </Item>
              </Grid>
              <Divider />
              <Grid size={3}>
                <Item>
                  <Box component="ul" sx={{ pl: 2, height: '380px' }}>

                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LEFL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LEQL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LESL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LEL')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LEV')}</React.Fragment>}/>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon sx={{ color: '#2e97ff'}}><CheckCircleRoundedIcon /></ListItemIcon>
                        <ListItemText secondary={<React.Fragment>{getString('LEC')}</React.Fragment>}/>
                      </ListItem>
                    </List>
                  </Box>
                </Item>
              </Grid>
              <Divider />              
            </Grid>
              
          </Grid>
          {error && <MessageSection error={getString(error)} />}
          {info && <MessageSection info={getString(info)} />}
          {message && <MessageSection info={getString(message)} />}
          {success && <MessageSection success={getString(success)} />}
         </Box>
    </Container>
  );
  };

  export default SubscriptionPage;
