import React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { Container, Button} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {fetchQuizNames} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import { downloadChart, printChart } from '../Utility/download';
import MessageSection from '../common/message';

const size = {
  width: 450,
  height: 250,
};

const ManagerDashboardPage = () => {
  const [topperCharError, setTopperCharError] =  useState("");
  const [participationChartError, setParticipationChartError] = useState("");
  const [piQuizNames, setPiQuizNames] = useState([]);
  const [sucessChartDataMap, setSuccessChartData] = useState([]);
  const [participationChartData, setParticipationChartData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    quiz_id:'',
    user_id:''
  });
  const [topperChartData, setTopperChartData] = useState({ months: [], scores: [], names: [] });
  
  const topperChartRef = useRef(null);
  const participationChartRef = useRef(null);
  const sucessChartRef = useRef(null);


  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setTopperCharError('');
        setParticipationChartError('');
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const quizNameData = await fetchQuizNames();
        setPiQuizNames(quizNameData);

        //fetch data for line chart
        const apiLineUrl = `api/report/quizlinegraphdata?user_id=${filters.user_id}`;
        const lineResponse = await axios.get(apiLineUrl,config);
        const data = lineResponse.data.data.map((item) => ({
          name: item.quiz_name,
          date: item.quiz_date ? new Date(item.quiz_date) : null,
          percentage: item.success_percentage,
        }));
        setSuccessChartData(data);


        //fetch data for topper chart
        const apiMgrBarUrl = `api/report/mgrtopscoregraphdata?quiz_id=${filters.quiz_id}`;
        axios.get(apiMgrBarUrl,config)
          .then(response => {
              const data = response.data.data;
              // Process the data to get top two scores per quiz for last 6 months
              const processedData = processTopperChartData(data);
              setTopperChartData(processedData);
          })
          .catch(error => {
            if (error.response && error.response.data && typeof error.response.data.error === 'string') {
              setTopperCharError(error.response.data.error );
            }
          });
        //fetch data for pi chart
        const apiPiUrl = `api/report/quizbargraphdata?${filters.quiz_id}`;
        const piResponse = await axios.get(apiPiUrl,config);
        setParticipationChartData(piResponse.data);
      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setParticipationChartError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
        } finally {
          setLoading(false);  // Stop the loading spinner
        }
    };

    fetchData();
  }, [navigate, filters.user_id, filters.quiz_id]);

  if (topperCharError) {
    return <MessageSection error={getString(topperCharError)} />;
  }
  if (participationChartError) {
    return <MessageSection error={getString(participationChartError)} />;
  }

  const processTopperChartData = (data) => {
    // Create a map to hold data grouped by month
    const monthMap = new Map();

    data.forEach(item => {
        const month = new Date(item.response_date).toLocaleString('default', { month: 'short', year: 'numeric' });
        if (!monthMap.has(month)) {
            monthMap.set(month, []);
        }
        monthMap.get(month).push({ score: item.score, name: item.Name });
    });

    // Get last 6 months of data
    const months = Array.from(monthMap.keys()).slice(-6);
    const scores = months.map(month => {
        const monthScores = monthMap.get(month) || [];
        // Sort scores in descending order and take the top two
        monthScores.sort((a, b) => b.score - a.score);
        return monthScores.slice(0, 5).map(item => item.score);
    });

    const names = months.map(month => {
        const monthScores = monthMap.get(month) || [];
        // Sort names corresponding to the top two scores
        monthScores.sort((a, b) => b.score - a.score);
        return monthScores.slice(0, 5).map(item => item.name);
    });

    return {
        months,
        scores,
        names
    };
  };

   // Render loading spinner or error message
   if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  const lineChartData = sucessChartDataMap.map((item) => item.percentage);
  const xAxisData = sucessChartDataMap.map((item) => item.date);

  // Handle form submit to set filters
  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if(e.target.name === "quiz_id")
    {
      try{
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
      //fetch data for pi chart
      const apiPiUrl = `api/report/quizbargraphdata?quiz_id=${e.target.value}`;
      const piResponse = await axios.get(apiPiUrl,config);
      setParticipationChartData(piResponse.data);

      const apiMgrBarUrl = `api/report/mgrtopscoregraphdata?quiz_id=${e.target.value}`;
      axios.get(apiMgrBarUrl,config)
        .then(response => {
            const data = response.data.data;
            // Process the data to get top two scores per quiz for last 6 months
            const processedData = processTopperChartData(data);
            setTopperChartData(processedData);
        })
        .catch(error => {
            console.error('Error fetching quiz scores:', error);
        });
      }catch (error) {
        setError('Error fetching data');
        if(error.status === 401)
          navigate('/logout');
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    }
      
  };



  return (
    <Container>
      <Box sx={{ py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant="h4">{getString('DASH')}</Typography>
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField select sx={{ minWidth: 150 }} label={getString('GLQZ')}  name="quiz_id" value={filters.quiz_id} onChange={handleFilterChange} >
              {piQuizNames.map((quiz) => (
                <MenuItem key={quiz.code} value={quiz.code}>
                  {quiz.description}
                </MenuItem>
              ))}
          </TextField> 
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={8}>
        <Grid size={{ xs: 6, md: 6 }}>
          <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
            <Stack direction="row" spacing={1} ><Typography variant="h6">{getString('DTOP')}</Typography></Stack>
            <div ref={topperChartRef}>
              <BarChart
                series={[
                          { 
                            data: topperChartData.scores.map(scores => scores[0] || 0), 
                            label: 'Rank 1',
                            valueFormatter: (value, { dataIndex }) => {
                              const name = topperChartData.names[dataIndex]?.[0] || '-';
                              const score = topperChartData.scores[dataIndex]?.[0] || 0;
                              return `${name}: ${score}`;
                          }
                          },
                        { 
                            data: topperChartData.scores.map(scores => scores[1] || 0), 
                            label: 'Rank 2',
                            valueFormatter: (value, { dataIndex }) => {
                              const name = topperChartData.names[dataIndex]?.[1] || '-';
                              const score = topperChartData.scores[dataIndex]?.[1] || 0;
                              return `${name}: ${score}`;
                          }
                        },
                        { 
                          data: topperChartData.scores.map(scores => scores[2] || 0), 
                          label: 'Rank 3',
                          valueFormatter: (value, { dataIndex }) => {
                            const name = topperChartData.names[dataIndex]?.[2] || '-';
                            const score = topperChartData.scores[dataIndex]?.[2] || 0;
                            return `${name}: ${score}`;
                        }
                        },
                        { 
                          data: topperChartData.scores.map(scores => scores[3] || 0), 
                          label: 'Rank 4',
                          valueFormatter: (value, { dataIndex }) => {
                            const name = topperChartData.names[dataIndex]?.[3] || '-';
                            const score = topperChartData.scores[dataIndex]?.[3] || 0;
                            return `${name}: ${score}`;
                        }
                        },
                        { 
                          data: topperChartData.scores.map(scores => scores[4] || 0), 
                          label: 'Rank 5',
                          valueFormatter: (value, { dataIndex }) => {
                            const name = topperChartData.names[dataIndex]?.[4] || '-';
                            const score = topperChartData.scores[dataIndex]?.[4] || 0;
                            return `${name}: ${score}`;
                        }
                        }
                      ]}
                xAxis={[
                    {
                        data: topperChartData.months,
                        label: getString('GLMN'),
                        scaleType: 'band',  // Set scaleType to 'band' for bar chart
                    }
                ]}
                yAxis={[{ label: getString('GLSC') }]}
                width={500}
                height={300}
              />
            </div>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => downloadChart(topperChartRef)} sx={{ mr: 2 }}>{getString('DLCR')}</Button>
              <Button variant="contained" color="secondary" onClick={() => printChart(topperChartRef)}>{getString('PRCR')}</Button>
            </Box>
        </Stack>
      </Grid>
      <Grid size={{ xs: 6, md: 6 }}>
        <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
        <Stack direction="row" spacing={1}><Typography variant="h6">{getString('DQSR')}</Typography></Stack>
          <div ref={participationChartRef}>
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.value.toFixed(0)}%`,
                  arcLabelMinAngle: 35,
                  arcLabelRadius: '60%',
                  ...participationChartData,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fontWeight: 'bold',
                },
              }}
              {...size}
            />
          </div>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => downloadChart(participationChartRef)} sx={{ mr: 2 }}>{getString('DLCR')}</Button>
              <Button variant="contained" color="secondary" onClick={() => printChart(participationChartRef)}>{getString('PRCR')}</Button>
            </Box>
        </Stack>
      </Grid>
      <Grid size={{ xs: 6, md: 12 }}>
        <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
          <Stack direction="row" spacing={1}><Typography variant="h6">{getString('DQSP')}</Typography></Stack>
          <div ref={sucessChartRef}>
            <LineChart
              height={300}
              grid={{ horizontal: true }}
              series={[
                {
                  data: lineChartData,
                  area: true,
                  lineColor: '#8884d8',  // Default line color
                  areaGradient: lineChartData.map(value => value >= 50 ? '#02B2AF' : '#b32600'),  // Color mapping based on threshold
                },
              ]}
              margin={{
                top: 10,
                bottom: 50,
              }}
              yAxis={[
                {
                  colorMap: {
                    type: 'piecewise',
                    thresholds: [50],
                    colors: ['#b32600', '#02B2AF'],  // Red below 50, Green above 50
                  },
                },
              ]}
              xAxis={[
                {
                  data: xAxisData,  
                  scaleType: 'time',
                  tickFormat: (value) => value.toLocaleDateString(),
                },
              ]}
            />
          </div>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => downloadChart(sucessChartRef)} sx={{ mr: 2 }}>{getString('DLCR')}</Button>
              <Button variant="contained" color="secondary" onClick={() => printChart(sucessChartRef)}>{getString('PRCR')}</Button>
            </Box>
        </Stack>
      </Grid>
    </Grid>
  </Container>

  );
}
export default ManagerDashboardPage;
