// DashboardPage.js
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

const QuizResponseEndPage = () => {

  const location = useLocation();
  const data = location.state?.data || {};
  const totalQuest = data.quiz_form_response.total_question
  const totalRightQuest = data.quiz_form_response.correct_response
  const totalScore = data.quiz_form_response.total_score

  return (
    <Box sx={{ py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Typography variant="h4">Quiz Results</Typography>
      <Typography variant="h5">Thanks for taking the Quiz.</Typography>
      <Typography> You got {totalRightQuest} correct questions out of {totalQuest} questions and your score is: {totalScore}.</Typography>
      <Typography> You can close the window</Typography>
    </Box>
  );
}
export default QuizResponseEndPage;
