import { format } from 'date-fns';

export const getDefaultDateRange = () => {
  const today = new Date();
  const fromDate = new Date(today);
  fromDate.setDate(today.getDate() - 30); // Set to 30 days ago
  today.setDate(today.getDate() + 1); // Set to tommorow

  const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
  const formattedToDate = format(today, 'yyyy-MM-dd');
 

  return { formattedToDate, formattedFromDate };
};

export const getFormatedDate = (date) => {

  if (!date) return ''; // Return empty string for invalid date
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return ''; // Return empty if date is invalid
  return parsedDate.toLocaleDateString();
  
  //const formattedDate = format(date, 'dd/MM/yyyy');
  //return formattedDate;
  };
