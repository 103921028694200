import {jwtDecode} from 'jwt-decode';

export const getUserRoleFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
     return decodedToken.sub.role;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const getUserIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.sub.user_id;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const getUserLoginIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.sub.name;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};

export const getTenantIdFromToken = () => {
  try {
    // Decode the token
    const token = localStorage.getItem('token'); 
    const decodedToken = jwtDecode(token);
    return decodedToken.sub.tenant_id;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};