import React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { Container, Button} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { downloadChart, printChart } from '../Utility/download';
import {fetchUserNames} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import MessageSection from '../common/message';

const barChartSetting = {
  xAxis: [
    {
      label: 'amount ($)',
    },
  ],
  width: 500,
  height: 400,
};
const AdminDashboardPage = () => {
  const [userNames, setUserNames] = useState([]);
  const [barData, setBarChartData] = useState([]);
  const [barDataError, setbarDataError] = useState("");

  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    user_id:''
  });

  const chartRef = useRef(null);

  useEffect(() => {
    setError(''); 
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const userNameData = await fetchUserNames();
        setUserNames(userNameData);
        //fetch data for bar chart
        
          const apiBarUrl = `api/report/costgraphdata?user_id=${filters.user_id}`;
          const barResponse = await axios.get(apiBarUrl,config);
          setBarChartData(barResponse.data.data);        
      } catch (error) {
        setError('Error fetching data');
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setbarDataError(error.response.data.error );
        } else {
          setbarDataError("An error occurred while saving the data.");
        }
        if(error.response && error.response.status === 401)
          navigate('/logout');
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [navigate, filters.user_id]);


   // Render loading spinner or error message
   if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <MessageSection error={getString(error)} />;
  }
  // Handle form submit to set filters
  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    if(e.target.name === "user_id")
    {
      try{
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        // Set up the headers including Authorization and Page-Name
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,  // Pass the current page name in a custom header
          },
        };
        const apiBarUrl = `api/report/costgraphdata?user_id=${e.target.value}`;
        const barResponse = await axios.get(apiBarUrl,config);
        setBarChartData(barResponse.data.data);

      }catch (error) {
        setError('Error fetching data');
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setbarDataError(error.response.data.error );
        } else {
          setbarDataError("An error occurred while saving the data.");
        }
        if(error.response && error.response.status === 401)
          navigate('/logout');
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    }  
  };

  return (
    <Container>
    <Box sx={{ py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Typography variant="h4">{getString('DASH')}</Typography>
    </Box>
    
    <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField select sx={{ minWidth: 150 }} label={getString('GLUR')} name="user_id" value={filters.user_id} onChange={handleFilterChange} >
            {userNames.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField> 
        </AccordionDetails>
      </Accordion>
    
    <Grid container spacing={8}>
      <Grid size={{ xs: 6, md: 6 }}>
        <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
          <Stack direction="row" spacing={1} >
            <Typography variant="h6">{getString('DCPM')}</Typography>
          </Stack>
          <div ref={chartRef}>
            <BarChart
              dataset={barData}
              yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
              series={[{ dataKey: 'total_cost', label: 'Cost Usage' }]}
              layout="horizontal"
              {...barChartSetting}
            />
          </div>
          
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" onClick={() => downloadChart(chartRef)} sx={{ mr: 2 }}>{getString('DLCR')}</Button>
            <Button variant="contained" color="secondary" onClick={() => printChart(chartRef)}>{getString('PRCR')}</Button>
          </Box>
          {barDataError && 
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error">{barDataError}</Alert>
            </Stack>
            }
          
        </Stack>
      </Grid>
      
      <Grid size={{ xs: 6, md: 6 }}>
        <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6">{getString('...')}</Typography>
          </Stack>
          
          </Stack>
        

      </Grid>
      <Grid size={{ xs: 6, md: 12 }}>
        <Stack direction="column" spacing={1} sx={{ width: '100%', maxWidth: 600 }}>
          <Stack direction="row" spacing={1}>
              <Typography variant="h6">{getString('...')}</Typography>
          </Stack>
          
        </Stack>
      </Grid>
    </Grid>
  </Container>

  );
}
export default AdminDashboardPage;
