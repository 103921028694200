import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const QuizQuestSheetPage = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  // State to handle errors
  const location = useLocation();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null); 

  const queryParams = new URLSearchParams(location.search);
  const qsd = queryParams.get('qsd'); 
  const qd = queryParams.get('qd');
  const duration = parseInt(queryParams.get('time'), 10) * 60;  
  const guesttoken = queryParams.get('guesttoken');

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        localStorage.setItem("guesttoken", guesttoken);
        const apiUrl = `api/quiz_response/quizform/quiz_id=${qd}`;
        const response = await axios.get(apiUrl, {
          params: {
            quiz_id: qd,
            quiz_sharing_id: qsd,
            guesttoken: guesttoken
          }
        });

        setData(response.data.quiz_forms);  // Set the fetched data to state
      } catch (error) {
        setError(error.response.data.error);
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();    
  }, []);

  const handlQuizQuestSheetSubmit = async (event) => {
    if (event) event.preventDefault(); 
    setLoading(true);  
    try {
      const apiUrl = 'api/quiz_response/create';
      const  response_rows = {
                      "quiz_id": qd,
                      "quiz_sharing_id": qsd,
                      "responder_response": selectedAnswers,
                      "guesttoken":guesttoken}
      const response = await axios.post(apiUrl, 
                                        response_rows,
                                        {  headers: { 'Content-Type': 'application/json' } }
                                      ).then(response => {
                                      const quiz_id = response.headers['quiz_id']
                                      // Navigate to the results page with the response data
                                        navigate('/quizresponseend', { state: { data: response.data} });
                                      })
                                      .catch(error => {
                                        console.error('There was an error!', error);
                                      });
    }catch (error) {
      console.error("Invalid data");
    }
    finally {
      setLoading(false); 
    }
  };


  const handleCheckboxChange = (quiz_forms_id, option, isChecked) => {
    setSelectedAnswers((prevAnswers) => {
      const currentSelections = prevAnswers[quiz_forms_id] || [];
      if (isChecked) {
        // Add option if checked
        return { ...prevAnswers, [quiz_forms_id]: [...currentSelections, option] };
      } else {
        // Remove option if unchecked
        return { ...prevAnswers, [quiz_forms_id]: currentSelections.filter(item => item !== option) };
      }
    });
  };

  // Timer logic
  useEffect(() => {
    // Retrieve or set the start time in localStorage
    let savedStartTime = localStorage.getItem('startTime');
    const startTime = savedStartTime ? new Date(savedStartTime) : new Date();
    
    // If there's no saved start time, save the current time as the start time
    if (!savedStartTime) {
      localStorage.setItem('startTime', startTime);
    }
  
    // Calculate the end time based on the duration
    const endTime = new Date(startTime.getTime() + duration * 1000);
  
    const interval = setInterval(() => {
    const currentTime = new Date();
    const timeRemaining = Math.floor((endTime - currentTime) / 1000);

    // If timeRemaining is less than or equal to 0, submit the form and clear the interval
    if (timeRemaining <= 0) {
      clearInterval(interval);
      handlQuizQuestSheetSubmit(); // Submit the form automatically
    } else {
      setTimeLeft(timeRemaining); // Update the remaining time state
    }
    }, 1000);
  
    // Cleanup on unmount
    return () => clearInterval(interval);
  }, [duration]);


  // Calculate time format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const renderOptions = (response, quiz_forms_id) => {
    // Split response string into an array and map to checkboxes
    return response.split('\n').filter(Boolean).map((option, index) => {
      const [label, value] = option.split(') ');
      const isChecked = selectedAnswers[quiz_forms_id]?.includes(label.trim()) || false;
      return (
        <div key={index}>
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(quiz_forms_id, label.trim(), e.target.checked)}
            />
            {option.trim()}
          </label>
        </div>
      );
    });
  };

  return (
    <Container>
      <CssBaseline enableColorScheme />
      <div>
        <Box sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          padding: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          textAlign: 'right'
        }}>
          <Grid container spacing={3}>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <Typography variant="h4" gutterBottom>Quiz!</Typography>
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                </FormControl>
              </FormGrid>
              <FormGrid size={{ xs: 12, md: 3 }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <Typography variant="h6">
                    Time Left: {timeLeft !== null ? formatTime(timeLeft) : 'Loading...'}
                  </Typography>
                </FormControl>
              </FormGrid>
            </Grid>  
        </Box>

          <form onSubmit={handlQuizQuestSheetSubmit}>
            <Box sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Grid container spacing={3}>
                <FormGrid size={{ xs: 12 }}>
                  <Box
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      flexGrow: 1,
                    }}
                  >
                    <div>
                      {data.map((quiz) => (
                        <div key={quiz.quiz_forms_id}>
                          <h3>{quiz.question_number}. {quiz.question}</h3>
                          {renderOptions(quiz.response, quiz.quiz_forms_id)}
                        </div>
                      ))}
                    </div>
                  </Box>
                </FormGrid>
                <FormGrid size={{ xs: 14, md: 3 }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                    <Box sx={{ '& button': { m: 1 } }}>                            
                      {
                        loading ? (<CircularProgress size={24}/>) : 
                        (<Button variant="contained" size="small" type="submit"color="primary">Finish Quiz</Button>)
                      }
                    </Box> 
                  </FormControl> 
                </FormGrid>           
              </Grid>
            </Box>                                  
          </form>
      </div>
    </Container>
  );
};

export default QuizQuestSheetPage;
