import axios from "axios";
import { useNavigate } from "react-router-dom";


const Logout = () => {
  const navigate = useNavigate();

  // Remove the JWT token or any user data stored in localStorage/sessionStorage
  localStorage.removeItem('token');  // assuming you store the token in localStorage
  // You can also clear the whole localStorage if you prefer:
  localStorage.clear();
    

  axios.post('api/auth/logout')
  .then(response => {
    // Navigate to the results page with the response data
    navigate('/login');
  })


  
};

export default Logout;
