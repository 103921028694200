import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import {getDefaultDateRange, getFormatedDate} from '../Utility/dateUtils';
import {fetchUserNames, fetchQuizNames, fetchCodeDescriptionMap, getCodeDescription} from '../Utility/getCodeDescription';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

// Now call the function after it's defined
const { formattedToDate, formattedFromDate } = getDefaultDateRange();

const QuizSharingPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null); 
  const [users, setUserNames] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [quizNames, setQuizNames] = useState([]);

  //model popup feilds
  const [selectedSharedQuiz, setSelectedSharedQuiz] = useState(null); 
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modelSubject, setModelSubject] = useState(''); 
  const [modelUser, setModelUser] = useState(''); 
  const [modelSharedUserName, setModelSharedUserName] = useState('');
  const [modelEmailId, setModelEmailId] = useState('');
  const [modelComment, setModelComment] = useState('');
  const [modelSharedStatus, setModelSharedStatus] = useState('');
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 

  const [filters, setFilters] = useState({
    create_date_from: formattedFromDate,
    create_date_to: formattedToDate
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; 
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage, 
          },
        };
        const apiUrl = `api/sharing/sharings?create_date_from=${filters.create_date_from}&create_date_to=${filters.create_date_to}`;
        const response = await axios.get(apiUrl,config);

        setData(response.data.sharing);  // Set the fetched data to state

        const userNameData = await fetchUserNames();
        setUserNames(userNameData);

        const quizNameData = await fetchQuizNames();
        setQuizNames(quizNameData);

        const statusesData = await fetchCodeDescriptionMap('shared_status');
        setStatuses(statusesData);
      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        } else {
          setError("An error occurred while fetching the data.");
        }
        if(error.response && error.response.status === 401)
          navigate('/logout');
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (quiz_share) => {
    setSelectedSharedQuiz(quiz_share); // Set the selected row for editing
    setModelSubject(quiz_share.quiz_id); 
    setModelUser(quiz_share.user_id); 
    setModelSharedUserName(quiz_share.shared_user_name); 
    setModelEmailId(quiz_share.email_id); 
    setModelComment(quiz_share.comment);
    setModelSharedStatus(quiz_share.hshared_status);
    setModelCreated(quiz_share.create_date);
    setModelTenant(quiz_share.tenant_id);
    setModelLastUpdate(quiz_share.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
  };

   // Function to handle Edit button click
   const handleEditClick = (quiz_share) => {
    setSelectedSharedQuiz(quiz_share); // Set the selected row for editing
    setModelSubject(quiz_share.quiz_id); 
    setModelUser(quiz_share.user_id); 
    setModelSharedUserName(quiz_share.shared_user_name); 
    setModelEmailId(quiz_share.email_id); 
    setModelComment(quiz_share.comment);
    setModelSharedStatus(quiz_share.hshared_status);
    setModelCreated(quiz_share.create_date);
    setModelTenant(quiz_share.tenant_id);
    setModelLastUpdate(quiz_share.last_update_date);
    setIsReadOnly(false);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    // Perform API call or state update to save changes
    const updatedQuizSharing = {
      ...selectedSharedQuiz,
      shared_user_name: modelSharedUserName, 
      email_id: modelEmailId,
      comment: modelComment,
      shared_status: modelSharedStatus
    };

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const prevData = await axios.patch(`api/sharing/update/quiz_sharing_id=${selectedSharedQuiz.quiz_sharing_id}`, updatedQuizSharing, config);

      // Update the user data in the state after successful save
      setData((prevData) =>
        prevData.map((shareingData) =>
          shareingData.quiz_sharing_id === selectedSharedQuiz.quiz_sharing_id ? updatedQuizSharing : shareingData
        )
      );
       

    } catch (error) {
      console.error('Error updating question data:', error);
    }

    setIsViewEditModalOpen(false); // Close the modal after saving
    setSelectedSharedQuiz(null);
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error && 
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">{error}</Alert>
      </Stack>
      }</div>;
  }

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 100,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    { field: 'quiz_id', headerName: getString('GLSB'), width: 180, editable: true },
    { field: 'shared_user_name', headerName: getString('GLST'), width: 150, editable: false },
    { field: 'email_id', headerName: getString('GLEM'), width: 250, editable: false },
    { field: 'comment', headerName: getString('GLCM'), width: 120, editable: false },
    { field: 'shared_status', headerName: getString('GLS'), width: 120, editable: false },
    { field: 'user_id', headerName: getString('GLCR'), width: 120, editable: false },
    { field: 'create_date', headerName: getString('GLCD'), width: 100, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((gridRow, index) => ({
    id: gridRow.quiz_sharing_id,                   
    user_id: getCodeDescription(gridRow.user_id,users),            
    quiz_id: getCodeDescription(gridRow.quiz_id,quizNames),
    shared_user_name: gridRow.shared_user_name, 
    email_id: gridRow.email_id,            
    comment: gridRow.comment,
    shared_status: getCodeDescription(gridRow.shared_status,statuses),
    hshared_status: gridRow.shared_status,                 
    create_date: getFormatedDate(gridRow.create_date),
    last_update_date: getFormatedDate(gridRow.last_update_date), 
    tenant_id: gridRow.tenant_id ,
    shared_user_id: gridRow.shared_user_id,
    quiz_sharing_id: gridRow.quiz_sharing_id
  }));

    // Handle form submit to set filters
    const handleFilterChange = (e) => {
      if(e.target.name === 'create_date_to' && e.target.value < filters.create_date_from)
      {
        alert(getString('GLDC'));
        return;
      }
      if(e.target.name === 'create_date_from' && e.target.value > filters.create_date_to)
      {
        alert(getString('GLDC'));
        return;
      }
      const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: value,
      });
    };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('QSLT')}</Typography>
        <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                    label={getString('GLFR')}
                    type="date"
                    name="create_date_from"
                    value={filters.create_date_from}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                />
              </FormControl>
            </FormGrid>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
                
              </FormControl>
            </FormGrid>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <TextField
                  label={getString('GLTO')}
                  type="date"
                  name="create_date_to"
                  value={filters.create_date_to}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                    />
              </FormControl>
            </FormGrid>            
          </Grid> 
        </AccordionDetails>
      </Accordion>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick

                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Bold font for the header titles
                    }
                  }}
                />
              </Box>      
            </Grid>                
          </Grid>

          {/* View/ Edit Modal */}
          <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{isReadOnly ? getString('GQSD') : getString('QSEQ')}</DialogTitle>
            <DialogContent>
              <TextField label={getString('GLSB')} fullWidth margin="normal" value={modelSubject} InputProps={{ readOnly: isReadOnly,}} disabled='true' />
              <TextField label={getString('GLST')} fullWidth margin="normal" value={modelSharedUserName} onChange={(e) => setModelSharedUserName(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('GLEM')} fullWidth margin="normal" value={modelEmailId} onChange={(e) => setModelEmailId(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
              <TextField label={getString('GLCM')} fullWidth margin="normal" value={modelComment} onChange={(e) => setModelComment(e.target.value)} multiline rows={4} InputProps={{ readOnly: isReadOnly,}} />
              <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelSharedStatus} onChange={(e) => setModelSharedStatus(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
                {statuses.map((dd) => (
                  <MenuItem key={dd.code} value={dd.code}>
                    {dd.description}
                  </MenuItem>
                ))}
              </TextField>

              <TextField label={getString('GLCR')} fullWidth margin="normal" value={modelUser} disabled='true' />
              <TextField label={getString('QSSO')} fullWidth margin="normal" value={modelCreated} disabled='true' />
              <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />
              <TextField label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} disabled='true' />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
              {!isReadOnly && (
                <Button onClick={handleSaveChanges} variant="contained">
                  {getString('GLSV')}
                </Button>
              )}
            </DialogActions>
          </Dialog>

    </Container>
);
};
export default QuizSharingPage;