import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import {getFormatedDate} from '../Utility/dateUtils';
import {fetchCodeDescriptionMap, getCodeDescription} from '../Utility/getCodeDescription';
import {isRequiredFiledIsEmpty } from "../Utility/validations";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {getString} from '../Utility/getSystemString';
import AddIcon from '@mui/icons-material/Add';
import MessageSection from '../common/message';


const TenantListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [statuses, setStatus] = useState([]);
  const [languageCodes, setLanguageCodes] = useState([]);  
  const [selectedTenant, setSelectedTenant] = useState(null); 
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modelname, setModelName] = useState('');
  const [modeTitle, setModelTitle] = useState('');
  const [modelStatus, setModelStatus] = useState('');
  const [modelLannguage, setModelLanguage] = useState('');
  const [modelComment, setModelComment] = useState('');
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [isReadOnly, setIsReadOnly] = useState(false); 
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');


  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        const apiUrl = `api/tenant/read`;
        const response = await axios.get(apiUrl, config);
        setData(response.data.tenant);

        const statusesData = await fetchCodeDescriptionMap('statuses');
        setStatus(statusesData);

        const languageCodeData = await fetchCodeDescriptionMap('user_languages');
        setLanguageCodes(languageCodeData);

      } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string')
          setError(error.response.data.error );
        if(error.status === 401)
          navigate('/logout');
      } finally {
        setLoading(false);  // Stop the loading spinner
      }
    };

    fetchData();
  }, [navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (tenant) => {
    setSelectedTenant(tenant); 
    setModelName(tenant.name); 
    setModelStatus(tenant.hStatus);
    setModelLanguage(tenant.hLanguageCode);
    setModelComment(tenant.comment);
    setModelCreated(tenant.create_date);
    setModelLastUpdate(tenant.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('VIEW'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (tenant) => {
    setSelectedTenant(tenant); 
    setModelName(tenant.name); 
    setModelStatus(tenant.hStatus);
    setModelLanguage(tenant.hLanguageCode);
    setModelComment(tenant.comment);
    setModelCreated(tenant.create_date);
    setModelLastUpdate(tenant.last_update_date);
    setIsReadOnly(false);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setModelTitle(getString('EDIT'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };
  
  // Function to handle add button click
  const handleAddClick = () => {
    setSelectedTenant(null);
    setModelName('');
    setModelStatus('ACTV');
    setModelLanguage('EN');
    setModelComment('');
    setIsReadOnly(false);
    setIsViewEditModalOpen(true);
    setIsNewRecord(true); 
    setModelTitle(getString('CRET'));
    setNameError(false);
    setNameErrorMessage('');
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
  };

  // Function to handle delete button click
  const handleDeleteClick =  async (tenant) => {
    setSelectedTenant(tenant); // Set the selected user for editing
    try {
          if(tenant.id === 0)
          {
            alert(getString('VMDT'))
            return;
          }

          const token = localStorage.getItem("token");
          const currentPage = window.location.pathname; 
          const config = {
            headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                  'Page-Name': currentPage,
                },
              };
          const delRes = await axios.delete(`api/tenant/delete/tenant_id=${tenant.id}`, config);
          const apiUrl = `api/tenant/read`;
          const response = await axios.get(apiUrl, config);
          setData(response.data.tenant);
          if(delRes.status === 200)
            setSuccess(delRes.data.msg);
    } catch (error) {
      if (error.response && error.response.data && typeof error.response.data.error === 'string')
        setError(error.response.data.error );
      if(error.status === 401)
        navigate('/logout');
    }  
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canClose = true;
    if(validateInputs())
    {
      const newTenant = {
        name: modelname,
        status: modelStatus,
        tenant_language: modelLannguage,
        comment: modelComment,
      };

      // Perform API call or state update to save changes
      const updatedTenant = {
        ...selectedTenant,
        name: modelname,
        status: modelStatus,
        tenant_language: modelLannguage,
        comment:modelComment,
      };

      try {
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname;
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        if (isNewRecord) {
          // Save new record
          const response = await axios.post(`api/tenant/create`, newTenant, config);
          setData((prevData) => [...prevData, response.data.tenant]);
          if(response.status === 201)
            setSuccess(response.data.msg);
        } else {
          const prevData = await axios.patch(`api/tenant/update/tenant_id=${selectedTenant.tenant_id}`, updatedTenant, config);
          // Update the user data in the state after successful save
          setData((prevData) =>
            prevData.map((tenant) =>
              tenant.tenant_id === selectedTenant.tenant_id ? updatedTenant : tenant
            )
        );
        if(prevData.status === 200)
          setSuccess(prevData.data.msg);
        }
       
      } catch (error) {
        canClose=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
          setModelError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
      if(canClose)
      {
        setIsViewEditModalOpen(false); // Close the modal after saving
        setSelectedTenant(null);
        setIsNewRecord(false);
      }
       
    }
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 200,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Icon */}
          <IconButton
            onClick={() => handleDeleteClick(params.row)}
            aria-label="delete"
            sx={{ color: '#2e97ff'}}
          >
            <DeleteIcon />
          </IconButton>

        </>
      ),
    },

    { field: 'name', headerName: getString('GLNM'), width: 230, editable: false },
    { field: 'tenant_language', headerName: getString('GLLN'), width: 200, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 160, editable: true },
    { field: 'comment', headerName: getString('GLCM'), width: 150, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  const rows = data.map((tenant, index) => ({
    id: tenant.tenant_id,                      // A unique ID for each row                     
    name: tenant.name,
    comment: tenant.comment,            
    status: getCodeDescription(tenant.status,statuses),
    hStatus: tenant.status, 
    hLanguageCode: tenant.tenant_language,
    tenant_language: getCodeDescription(tenant.tenant_language,languageCodes),    
    create_date: getFormatedDate(tenant.create_date),
    last_update_date: getFormatedDate(tenant.last_update_date),
    tenant_id: tenant.tenant_id
  }));

  const validateInputs = () => {
    let isValid = true;
    const name = document.getElementById('name');
    if (!isRequiredFiledIsEmpty(name)) {
      setNameError(true);
      setNameErrorMessage(getString('VMNR'));
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    return isValid;
  };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('AQTN')}</Typography>
       
        <IconButton color="primary" onClick={() => handleAddClick()}>
            <AddIcon fontSize="large" />
        </IconButton>
      
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                    pageSize: 5,
                    },
                    },
                  }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick

                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 'bold', // Bold font for the header titles
                      }
                    }}
              />
            </Box>      
          </Grid>       
        </Grid>
        {error && <MessageSection error={getString(error)} />}
        {info && <MessageSection info={getString(info)} />}
        {success && <MessageSection success={getString(success)} />}

        {/* View/ Edit User Modal */}
        <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
          <DialogTitle>{modeTitle}</DialogTitle>
          <DialogContent>
            <TextField
              label={getString('GLNM')}
              fullWidth
              margin="normal"
              value={modelname}
              name="name"
              id="name"
              onChange={(e) => setModelName(e.target.value)}
              InputProps={{ readOnly: isReadOnly,}}
              error={nameError}
              helperText={nameErrorMessage}
              color={nameError ? 'error' : 'primary'}
            />
              
            <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} onChange={(e) => setModelStatus(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
              {statuses.map((dd) => (
                <MenuItem key={dd.code} value={dd.code}>
                  {dd.description}
                </MenuItem>
              ))}
            </TextField>

            <TextField select label={getString('GLLN')} fullWidth  margin="normal" value={modelLannguage} onChange={(e) => setModelLanguage(e.target.value)} InputProps={{ readOnly: isReadOnly,}}>
              {languageCodes.map((dd) => (
                <MenuItem key={dd.code} value={dd.code}>
                  {dd.description}
                </MenuItem>
              ))}
            </TextField>
          
            <TextField label={getString('GLCM')} fullWidth margin="normal" value={modelComment} onChange={(e) => setModelComment(e.target.value)} multiline rows={4} InputProps={{ readOnly: isReadOnly,}} />
            {isReadOnly && <TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} disabled='true' />}
            {isReadOnly && <TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} disabled='true' />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
            {!isReadOnly && ( <Button onClick={handleSaveChanges} variant="contained">{getString('GLSV')} </Button>)}
          </DialogActions>
          {modelError && <MessageSection error={getString(modelError)} />}
        </Dialog>  
    </Container>
  );
};

export default TenantListPage;
